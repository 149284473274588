<script>
import TablePage from '../../../../../../components/table_page';
import Form from './form.vue';
import * as utils from '../../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaVisitStepActivityExecutionController/findVisitStepDisplayActivityExecutionEsList',
      formConfig: {},
      columnsConfig: [], // 表格搜索框配置
      // 请求参数
      paramsProps: {
        stepCode: 'sfa_step_code_display',
      },
      /** @desc 搜索框绑定数据 */
      formData: {},
      searchFormData: {},
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'add') this.btnAdd();
      if (val.code === 'edit') this.btnEdit(row);
      if (val.code === 'view') this.btnCheck(row);
      if (val.code === 'display_pass') this.displayPass(); // 陈列审核通过
      if (val.code === 'display_reject') this.displayReject(); // 陈列审核驳回
    },
    btnAdd() {
      this.formName = 'Form';
      this.formConfig.row = {};
      this.formConfig.disabled = false;
      this.modalConfig.title = '新增';
      this.openFull();
    },
    btnCheck() {
      this.formName = 'Form';
      this.formConfig.row = {};
      this.formConfig.disabled = false;
      this.modalConfig.title = '查看';
      this.openFull();
    },
    displayPass() {
      console.log('displayCheckPass');
    },
    displayReject() {
      console.log('display reject');
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfaCenter_activityActivityReport_displayExecuteDetail');
  },

};
</script>
