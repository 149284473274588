<script>
import Form, { formCreate } from '../../../../../../components/form';

export default {
  extends: Form,
  data() {
    return {

    };
  },
  methods: {

  },
  created() {
  },
};
</script>
